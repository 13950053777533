//
// Google font - Poppins
//

// @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');

// /* latin-ext */
// @font-face {
//     font-family: 'Poppins';
//     font-style: normal;
//     font-weight: 500;
//     font-display: swap;
//     src: url(../../../fonts/SVN-Poppins-SemiBold.ttf);
//     // unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }

/* latin-ext */
// @font-face {
//     font-family: 'Poppins';
//     font-style: normal;
//     font-weight: 600;
//     font-display: swap;
//     src: url(../../../fonts/SVN-Poppins-SemiBold.ttf);
//     // unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }

// /* latin-ext */
// @font-face {
//     font-family: 'Poppins';
//     font-style: normal;
//     font-weight: 700;
//     font-display: swap;
//     src: url(../../../fonts/SVN-Poppins-Bold.ttf);
//     // unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }

@font-face {
    font-family: 'Google Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../../../fonts/GoogleSans-Medium.ttf);
    // unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}