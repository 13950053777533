.vertical-collpsed .simplebar-offset {
  max-height: 100vh !important;
  overflow: scroll !important;
  position: fixed;
}

.vertical-collpsed .simplebar-offset::-webkit-scrollbar {
  display: none;
}

@supports (-moz-appearance:none) {
  .sidebar-enable .simplebar-offset {
    scrollbar-color: transparent transparent;
  }
}

.vertical-collpsed .simplebar-offset {
  max-width: 70px !important;
}

.vertical-collpsed .simplebar-offset:hover {
  max-width: 260px !important;
}

.import-upload-table-name {
  padding: 10px;
  font-size: 20px;
  position: relative;
  visibility: hidden;
}

.import-upload-table-name:hover {
  background-color: aliceblue;
}

.import-upload-table-name-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.import-upload-table-name-icon:hover {
  cursor: pointer;
}

.import-upload-table-name:has(.import-upload-table-name-icon) {
  visibility: unset;
}

.tooltip-custom {
  position: relative;
  display: inline-block;
}

.tooltip-custom .tooltiptext-custom-button-icon-left {
  visibility: hidden;
  width: 120px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-custom .tooltiptext-custom-button-icon-left::after {
  content: "";
  position: absolute;
  top: 100%;
  left: calc(100% - 10px);
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip-custom:hover .tooltiptext-custom-button-icon-left {
  visibility: visible;
  opacity: 1;
}

.text-result-color a {
  color: unset;
}

#sidebar-menu a {
  white-space: pre-wrap;
}

.vertical-collpsed .version-release {
  display: none !important;


}

.table-tat-settings,
.table-insurance-settings {
  height: 100%;
}

.table-tat-settings .overflowYhidden,
.table-insurance-settings .overflowYhidden {
  overflow-y: unset !important;
  overflow: visible !important;
}

.tat-setting-content .tab-content {
  height: calc(100% - 41px);
}

.tat-setting-content .tab-pane {
  height: 100%;
}

.tat-setting-content .tab-pane .table-tat-settings,
.tat-setting-content .tab-pane .table-insurance-settings {
  height: calc(100% - 0px); //height: calc(100% - 40px);
}

.tat-setting-content .table-scrollable {
  overflow: auto !important;
}

.table-tat-settings .dropdown-menu,
.table-insurance-settings .dropdown-menu {
  position: unset !important;
  min-width: 200px !important;
}

.input-table-header-tat-setting {
  display: flex;
}

.input-table-header-tat-setting .me-2 {
  margin-right: 0 !important;
  margin-left: 4px !important;
}

.table-tat-settings .px-2,
.table-insurance-settings .px-2 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.tat-setting-default form .mb-3 {
  margin-bottom: 0 !important;
}

.btn-soft-copy {
  background-color: rgba(85, 110, 230, .1);
  border-color: transparent;
  color: #556ee6;
  transition: all .4s ease;
}

.btn-soft-info {
  background-color: rgba(80, 165, 241, .1);
  border-color: transparent;
  color: #50a5f1;
  transition: all .4s ease;
}

.btn-soft-danger {
  background-color: hsla(0, 86%, 69%, .1);
  border-color: transparent;
  color: #f46a6a;
  transition: all .4s ease;
}

.btn-soft-secondary {
  background-color: rgba(116, 120, 141, .1);
  border-color: transparent;
  color: #6c757d;
  transition: all .4s ease;
}

.btn-soft-success {
  background-color: rgba(52, 195, 143, .1);
  border-color: transparent;
  color: #34c38f;
  ;
  transition: all .4s ease;
}

.btn-soft-warning {
  background-color: rgba(241, 180, 76, .1);
  border-color: transparent;
  color: #f1b44c;
  transition: all .4s ease;
}

.btn-soft-dark {
  background-color: rgba(52, 58, 64, .1);
  border-color: transparent;
  color: #343a40;
  transition: all .4s ease;
}

.selection-cell-header-tat,
.selection-cell-tat {
  width: 440px !important;
}

.selection-cell-header-tat,
.selection-cell-tat {
  width: 350px !important;
  max-width: 350px !important;
  min-width: 350px !important;
  position: sticky;
  left: 0px;
  z-index: 11 !important;
}

.selection-cell-header-tat {
  z-index: 11 !important;
}

.table-rule-management thead tr .selection-cell-header-tat {
  z-index: 13 !important;
}

@keyframes AnimationsTATLeft {
  0% {
    width: 16%;
    overflow: hidden;
  }

  100% {
    width: 0%;
    display: none;
  }
}

.TATSettingLeft {
  animation-name: AnimationsTATLeft;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes AnimationsTATRight {
  0% {
    width: 80%;
  }

  100% {
    width: 100%;
  }
}

.TATSettingRight {
  animation-delay: 0.2s;
  animation-name: AnimationsTATRight;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.resize-button-tat {
  position: absolute;
  left: 0;
  top: 10px;
  z-index: 9;
  border: 1px solid #000;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  line-height: 24px;
  color: #44546F;
  padding: 0;
  text-align: center;
  cursor: pointer;
  background: '#fff';
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  border: none;
  font-size: 10px;
}

.resize-button-tat:hover {
  background-color: #0C66E4;
  color: #fff;
}

@keyframes resize-button-tat-icon {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}

.resize-button-tat-icon {
  animation: resize-button-tat-icon 0.3s linear 1;
  animation-fill-mode: forwards;
}

.tooltip-custom .tooltiptext-custom-button-clone-tat {
  visibility: hidden;
  width: 120px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 0px;
  left: 0px;
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 20px;
}

.tooltip-custom .tooltiptext-custom-button-clone-tat::after {
  content: "";
  position: absolute;
  top: 10px;
  left: -5px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}

.tooltip-custom:hover .tooltiptext-custom-button-clone-tat {
  visibility: visible;
  opacity: 1;
}

.custom-tat-label-result {
  background-color: red !important;
  color: #fff !important;
  border: 1px solid red !important;
}

.page-content-result-detail .sticky-header-detail {
  top: 0 !important;
}

.page-content-result-detail .layout-pane .px-2 {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}

.table-title {
  font-size: 18px;
  font-weight: bold;
  color: #495057;
}

.right-pane-result-detail .search-label {
  width: 100% !important;
}

.right-pane-result-detail .me-2 {
  margin-right: 0 !important;
}

.header-result-detail .card-body {
  padding: 0 !important;
  padding-top: 10px !important;
}

.header-result-detail .card {
  margin-bottom: 0 !important;
}

.header-result-detail .accordion {
  border-radius: 5px;
  border: 1px solid #DDDDDD;
}

.header-result-detail .accordion-list {
  padding-left: 10px !important;
  padding-right: 10px !important;
  background-color: #fff;
  border-bottom: 1px solid #DDDDDD;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.header-result-detail .accordion-body {
  padding: 15px !important;
  padding-bottom: 0 !important;
}

// .header-result-detail .dripicons-chevron-down {
//   display: none;
// }

// .header-result-detail .dripicons-chevron-up {
//   display: none;
// }

.header-result-detail .header-result-detail-label {
  font-size: 13px;
}

.header-result-detail .header-result-detail-value {
  font-weight: bold;
  font-size: 13px;
  margin-left: 5px;
}

.header-result-detail-content {
  padding-right: 0 !important;
}

.header-result-detail .custom-accordion .mb-3:first-child {
  margin-bottom: 0 !important;
}

.result-detail-conclude-title {
  margin-left: -12px;
  font-size: 18px;
  font-weight: bold;
  height: 24px;
  line-height: 24px;
  color: #B09529;
}

.result-detail-conclude {
  color: #6571A7;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  font-weight: normal;
  margin-left: 5px;
  background-color: #F2F2F2;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.state-result-header-detail .text-muted {
  display: none;
}

.state-result-header-detail .badge {
  line-height: 16px;
}

.right-pane-result-detail .flatpickr-input {
  height: 38px !important;
  color: #808080 !important;
  padding-right: 6px;
}

.result-right-frame-table th,
.result-right-frame-table td {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.result-detail-bottom-column {
  height: 1px;
}

.result-detail-table>.row:first-child {
  height: 100% !important;
}

.result-detail-table>.row:first-child .card:first-child {
  height: 100% !important;
  box-shadow: none;
}

.result-detail-table>.row:first-child .card:first-child .card-body {
  height: calc(100% - 43px);
  padding-bottom: 0 !important;
}

.result-detail-table>.table-result-detail {
  height: 100%;
}

.result-detail-table .table-result-detail {
  height: calc(100% - 35px);
}

.result-detail-table .table-result-detail .table-expand-custom:first-child {
  height: 100%;
}

.result-detail-table .table-result-detail .table-expand-custom:first-child>.table-scrollable {
  height: calc(100% - 35px);
  overflow: auto !important;
  min-height: 300px;
}

.result-detail-table .table-result-detail .table-expand-custom:first-child>.table-scrollable>.react-bootstrap-table {
  height: 100%;
  overflow: unset;
  min-height: 250px;
  position: relative;
}

.result-detail-table .table-result-detail .table-expand-custom:first-child>.table-scrollable>.table-content-before-render {
  clip-path: inset(0 0 0 0);
}

.result-detail-table .table-result-detail .table-expand-custom:first-child>.table-scrollable>.table-content-before-render>.react-bootstrap-table>table>thead>tr>th {
  position: sticky;
  z-index: 5;
}

.check-option:hover {
  background-color: #deebff
}

.diable-filter-form-table {
  pointer-events: none;
  opacity: 0.5;
}

.diable-filter-form-table:hover {
  cursor: not-allowed;
}

// .header-result-detail .card-header {
//   padding-left: 0;
//   padding-right: 0;
// }

.result-detail-table .fa-arrow-right {
  margin-right: 5px;
}

.result-detail-table .table-result-detail .table-expand-custom:first-child>.table-scrollable>.react-bootstrap-table>table>thead {
  position: sticky;
  top: 0;
}

.result-detail-table .table-result-detail .table-expand-custom:first-child>.table-scrollable>.react-bootstrap-table>table>thead>th {
  z-index: 5;
}

.header-result-detail-content-summary .header-result-detail-value {
  margin-right: 10px;
}

.result-right-frame-table .selection-cell-header,
.result-right-frame-table .selection-cell {
  text-align: center;
}

.table-result-detail tr td,
.table-result-detail tr th {
  background-color: #fff;
}

.table-result-detail thead tr th {
  background-color: #eff2f7;
}

.table-result-detail thead {
  z-index: 9;
}



.table-result-detail .table-hover tbody tr:hover .test-result-detail-title-table-result-name,
.table-result-detail .table-hover tbody tr:hover .test-result-detail-title-table-result-name .item-fixed-result-table-detail,
.table-result-detail .table-hover tbody tr:hover .test-result-detail-title-table-no .item-fixed-result-table-detail,
.table-result-detail .table-hover tbody tr:hover .test-result-detail-title-table-check .item-fixed-result-table-detail {
  background-color: #f8f9fa !important;
  color: #495057;
}

.table-result-detail .table-hover tbody tr:hover td {
  background-color: #f8f9fa;
  color: #495057;
}

.table-result-detail .table-hover tbody tr .test-result-detail-title-table-result-name,
.table-result-detail .table-hover tbody tr .test-result-detail-title-table-result-name .item-fixed-result-table-detail,
.table-result-detail .table-hover tbody tr .test-result-detail-title-table-no .item-fixed-result-table-detail,
.table-result-detail .table-hover tbody tr .test-result-detail-title-table-check .item-fixed-result-table-detail {
  background-color: #fff !important;
  color: #495057;
}

.result-right-frame-table .selection-cell,
.result-right-frame-table .selection-cell-header {
  width: 10px !important;
}

.badge-test {
  background-color: #34c38f;
  color: white;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  align-items: center;
  font-size: 10px;
}

.badge-profile {
  background-color: #556ee6;
  color: white;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  align-items: center;
  font-size: 10px;
}

.badge-group {
  background-color: #f57575;
  color: white;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  align-items: center;
  font-size: 10px;
}

.ip-address {
  font-family: monospace;
  padding: 0.2em 0.4em;
  background-color: #f0f0f0;
}

.badge-type-s {
  background-color: #556ee62e !important;
  color: #556ee6;
  font-weight: bold;
  opacity: 1;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  align-items: center;
  font-size: 10px;
}

.badge-type-c {
  background-color: #34c38f2e !important;
  color: #34c38f;
  font-weight: bold;
  opacity: 1;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  align-items: center;
  font-size: 10px;
}

.sid-custom {
  color: #5F5F5F;
  background-color: #fff;
  padding: 1px 5px;
  margin-right: 5px;
  border-radius: 5px;
  border: 1px solid #808080;
  min-width: 40px;
  text-align: center;
}

.result-color-0 {
  color: #000 !important;
  font-weight: bold !important;
  text-align: center;
}

.result-color-1,
.result-color-1 a {
  color: blue !important;
  font-weight: bold !important;
  text-align: left;
}

.result-color-2,
.result-color-2 a {
  color: red !important;
  font-weight: bold !important;
  text-align: right;
}

.result-color-3,
.result-color-3 a {
  color: #62B257 !important;
  font-weight: bold !important;
  text-align: left;
}

.result-color-4,
.result-color-4 a {
  color: #f182f1 !important;
  font-weight: bold !important;
  text-align: right;
}

.result-text-color-0,
.result-text-color-0 a {
  color: #000 !important;
  font-weight: bold !important;
  text-align: center;
}

.result-text-color-1,
.result-text-color-1 a {
  color: blue !important;
  font-weight: bold !important;
  text-align: left;
}

.result-text-color-2,
.result-text-color-2 a {
  color: red !important;
  font-weight: bold !important;
  text-align: right;
}

.result-text-color-3,
.result-text-color-3 a {
  color: #62B257 !important;
  font-weight: bold !important;
  text-align: left;
}

.result-text-color-4,
.result-text-color-4 a {
  color: #f182f1 !important;
  font-weight: bold !important;
  text-align: right;
}

.connector-profile-table table tr th,
.connector-profile-table table tr td {
  padding: 5px;
}

.connector-profile-table table .inline-field {
  padding: 0;
}

.badge-gender {
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  align-items: center
}

.header-result-detail .card-body {
  display: none;
}

.sumamry-info-result-detail {
  margin-top: 10px;

}

.sumamry-info-result-detail .mb-3 {
  margin-bottom: 0 !important;
}

.sumamry-info-result-detail .accordion-list {
  background-color: #fff;
}

.sumamry-info-result-detail .accordion {
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.sumamry-info-result-detail .accordion-list {
  padding: 4px 10px;
}

.state-result-header-detail {
  width: 80px;
}

.state-result-header-detail .badge {
  height: 18px;
}

.result-right-frame-table {
  height: 100%;
  overflow: hidden;
}

.result-right-frame-table .table-responsive {
  height: calc(100% - 100px);
}

.result-right-frame-table table thead th {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  z-index: 1;
}

.result-right-frame-table .inner-custom-pagination .d-inline {
  display: none !important;
}

.result-right-frame-table .inner-custom-pagination .pagination {
  margin-bottom: 0 !important;
}

#btn-blur-insurance {
  border: none;
  background-color: transparent;
  position: absolute;
}

.table-insurance-settings .select2-selection__menu {
  width: 200px !important;
  z-index: 99 !important;
}

.table-parameter-code-detail .select2-selection__menu {
  width: 230px !important;
}

.table-insurance-settings .select2-selection__control,
.table-insurance-settings .select2-selection__indicators {
  min-height: 30px;
  height: 30px;
}

.table-insurance-settings>.table-scrollable>.react-bootstrap-table>table>tbody {
  clip-path: inset(0);
}

.table-insurance-settings .dropdown-menu {
  padding: 0;
}

.page-content-result-detail .tab-history-detail-result .testsTabTable {
  height: 100%;
}

.page-content-result-detail .tab-history-detail-result .testsTabTable .history-result-detail-content {
  height: 100% !important;
  overflow: unset !important;
}

.page-content-result-detail .tab-history-detail-result .testsTabTable>.row {
  height: 100%
}

.page-content-result-detail .tab-history-detail-result .testsTabTable>.row>.card {
  height: 100%;
  box-shadow: none;
}

.page-content-result-detail .tab-history-detail-result .testsTabTable>.row>.card>.card-body {
  height: calc(100% - 33px);
  overflow: auto;
}

.table-parameter-code-detail .select2-selection__control {
  min-height: 35px !important;
  height: 35px !important;
}

.table-parameter-code-detail .custom-popover-confirm {
  margin-top: -33px !important;
}

.table-general-result .table-scrollable {
  height: calc(100% - 20px);
}

body[data-layout="horizontal"] {
  .page-content>.container-fluid {
    max-width: 98%;
  }

  .container-fluid {
    max-width: 100%;
  }

  .topnav {
    margin-bottom: 10px;
    background-color: #556EE6;
  }

  .topnav .container-fluid {
    max-width: 95%;
  }

  // .card{
  //   border-radius: 15px;
  // }
  #topnav-menu-content .first-item>a {
    color: #fff;
  }

  #topnav-menu-content .first-item {
    align-self: center;
  }

  #topnav-menu-content .first-item.active>a {
    background-color: #8180E6;
    padding: 10px;
    border-radius: 10px;
  }

  #page-topbar {
    background-color: #fff;
  }

  #page-topbar .navbar-header span,
  #page-topbar .navbar-header a,
  #page-topbar .navbar-header i {
    color: #000 !important;
  }

  #page-topbar #page-header-notifications-dropdown span {
    color: #fff !important;
  }

  .dropdown-megamenu[style] {
    left: 335px !important
  }
}

.table-result-detail .react-bootstrap-table th {
  padding: 10px;
}

.connector-hiscode,
.connector-hiscode>.row,
.connector-hiscode>.row>.card,
.connector-hiscode>.row>.card>.card-body,
.connector-hiscode>.row>.card>.card-body>.container-fluid,
.connector-hiscode>.row>.card>.card-body>.container-fluid>.connector-profile-table {
  height: 100%;
}

.connector-hiscode>.row>.card>.card-body>.container-fluid>.connector-profile-table .table-responsive {
  height: calc(100% - 33px);
  overflow: auto;
}

.connector-content .custom-popaaaaover-confirm {
  z-index: 1;
}

.connector-hiscode>.row>.card>.card-body>.container-fluid>.connector-profile-table .table-responsive table thead tr th {
  position: sticky;
  top: 0;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.text-align {
  margin-left: 15px;
}

.page-content-patient-visit {
  padding: calc(70px + 8px) calc(24px / 2) 60px calc(24px / 2);
}

.split-content {
  height: calc(100% - 8px);
}

.page-content-delivery {
  padding: calc(70px + 8px) calc(8px / 2) 17px calc(8px / 2);
}

.table-delivery table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.text-right {
  text-align: right;
}

.pdr-0 {
  padding-right: 0 !important;
}

.pdl-0 {
  padding-left: 0 !important;
}

.group-patient-collapse {
  border: 1px solid #e8e9eb;
  border-radius: 10px;
  padding: 15px;
}

.group-patient-collapse table tr th {
  border: 1px solid #e8e9eb;
}

.header-result-detail-value .form-switch .col-sm-7 {
  display: none;
}

.test-request-table-toolbar .toolbar {
  display: flex;
}

.test-request-table-toolbar .toolbar .flatpickr-input {
  height: 33px;
}

.test-request-table-toolbar .search-label {
  margin-bottom: 0;
}

.test-request-table-toolbar .toolbar .select2-selection__control {
  min-height: 33px !important;
  max-height: 33px !important;
}

.test-request-table-toolbar .toolbar .select2-selection__option {
  text-align: left !important;
}

.patient-visit .select2-selection__control {
  min-height: 36px !important;
  max-height: 36px !important;
}

.sortable {
  padding: 8px !important;
  padding-left: 10px !important;
}

.table-patient-visit tr:has(td #watch_end_of_document_isWating),
.table-patient-visit tr:has(td #watch_end_of_document_notWating) {
  visibility: hidden;
}

.table-patient-visit th,
.table-patient-visit td {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.table-patient-visit .selection-cell-header,
.table-patient-visit .selection-cell {
  width: 20px !important;
}

.result-test-color {
  border-left-width: 3px;
  border-left-color: rgb(60, 60, 248);
}

.table-responsive {
  // max-height: 2800px !important;
}

.instrument-management .page-content {
  padding-top: 0;
}

.login-content input {
  font-size: 16px !important;
}

.label-input-login {
  font-size: 16px !important;
}

.instrument-translator-input .mb-3 {
  margin-bottom: 0 !important;
  width: calc(100% - 75px);
}

.instrument-translator .icon-content {
  right: -10px;
  top: 45%;
  cursor: pointer;
}

.instrument-translator .icon {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: 1px solid #535857;
  text-align: center;
}

.instrument-translator i {
  color: red;
}

.instrument-translator-input button {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.date-time-delivery {
  :nth-child(2)>.flatpickr-input {
    width: 150px;
    border: 0;
    background-color: inherit;
  }
}

#grouprequestmodal-patient-group {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.group-patient-patient-list-table .table-scrollable {
  max-height: unset !important;
}

.delete-type .dropdown button {
  margin: 0;
}

.collected-or-received .border-left-organ-2px {
  border-left: 5px solid orange;
}

.collected-or-received .border-left-organ-2px input {
  margin-left: -3px;
}

.table-expand-custom-result-detail {
  overflow: auto;
}

.table-expand-custom-result-detail th,
.table-expand-custom-result-detail td {
  padding: 5px !important;
}

// .table-expand-custom-result-detail table.table-bordered>thead>tr>th {
//   border: 1px solid #F0F2F7;
// }

.table-expand-custom-result-detail tr .padding-0 {
  padding: 0 !important;
  border-width: 0px !important;
}

.patient-group-import-model:has(.current-request-modal-general)
{
  overflow-y: auto !important;
}

.choose-his-patient-group button{
  min-width: 100px;
}

.table-expand-custom-result-detail table {
  border-collapse: separate;
  border-spacing: 0;
}


.table-result-expanded:has(+tr > .padding-0) td .fa-arrow-right{
  transform: rotate(0deg) ;
  transition: 0.8s ;
}

.table-result-expanded:has(+tr > .padding-0 > .show) td .fa-arrow-right{
  transform: rotate(90deg) !important;
  transition: 0.8s ;
}

.table-result-expanded-child:has(+tr .padding-0 .show) .fa-arrow-right{
  transform: rotate(90deg);
  transition: 0.8s ;
}

.table-result-expanded-child:has(+tr .padding-0) .fa-arrow-right{
  transform: rotate(0deg) ;
  transition: 0.8s ;
}

.result-detail-state-header{
  position: sticky !important;
  right: 0px !important;
  z-index: 6 !important;
}

.result-detail-state{
  position: sticky !important;
  right: 0px !important;
  z-index: 5 !important;
}
.item-fixed-result-table-detail-content-header{
  min-width: 142px !important;
}

.item-fixed-result-table-detail-content-resultText{
  min-width: 138px !important;
}

.result-detail-state, .result-detail-state-header{
  min-width: 70px !important;
  width: 70px !important;
  max-width: 70px !important;
}
.result-detail-state .badge-content-text{
  font-size: 8px !important;
}

.result-detail-state .badge-width{
  min-width: 60px;
  max-width: 65px;
}

.badge-test, .badge-profile{
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.autowidthnormalRange>.autowidthnormalRange, .autowidthunit>.autowidthunit {
  min-width: 142px !important;
  max-width: 142px !important;
  width: 142px !important;
}

.resultBlurItemFocusnormalRange, .resultBlurItemFocusunit{
  width: 138px !important;
}

.table-connector-log .toolbar{
  display: flex;
}

.table-connector-log .toolbar .flatpickr-input{
  height: 33px;
}

.result-detail-normalRange,.result-detail-unit {
  min-width: 150px !important;
  max-width: 150px !important;
  width: 150px !important;
}

.page-content-result-detail .tab-history-detail-result .table-result-auditlog .table-responsive{
  max-height: unset !important;
}

.result-status-send-file{
  width: 12px;
  height: 12px;
  line-height: 12px;
  background-color: #34c38f;
  color: #fff;
  border-radius: 50%;
  margin-right: 5px;
  text-align: center;
  min-width: 12px;
  max-width: 12px;
  font-size: 11px;
  flex: 1;
}

.crossed {
  background:
      linear-gradient(to top left,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) calc(50% - 0.8px),
          rgba(0, 0, 0, 1) 50%,
          rgba(0, 0, 0, 0) calc(50% + 0.8px),
          rgba(0, 0, 0, 0) 100%),
      linear-gradient(to top right,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) calc(50% - 0.8px),
          rgba(0, 0, 0, 1) 50%,
          rgba(0, 0, 0, 0) calc(50% + 0.8px),
          rgba(0, 0, 0, 0) 100%);
}
.table-patient-barcode tr:has(td #watch_end_of_document_barcode){
  visibility: hidden;
}

.table-patient-barcode tr td .watch_end_of_document_barcode_loading{
  visibility: visible;
}

.autowidthdownload{
  vertical-align: middle;
}

.select-physican .mb-3{
  margin-bottom: 6px !important;
}

.result-detail-name:has(.result-detail-name-0):hover:has(.mdi-pin){
  z-index: 10 !important;
}